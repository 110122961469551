<template>
  <b-card>
    <h3 class="text-center mt-4 mb-4">Omdirigeras...</h3>
  </b-card>
</template>

<script>

export default {
  name: 'payment-callback',
  data() {
    return {
      
    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    
  }
};
</script>
